


































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { Article } from "@/core/models";
import ArticleItem from "./components/ArticleItem.vue";
import AddArticleButton from "./components/AddArticleButton.vue";

@Component({ components: { ArticleItem, AddArticleButton } })
export default class News extends Vue {
  @Getter("news/loading") loading!: boolean;
  @Getter("news/retrieved") retrieved!: boolean;
  @Getter("news/getArticles") articles!: Article[];
  @Action("news/getArticles") getArticles!: () => Promise<void>;
  @Getter("profile/getUserHasPrivileges") hasPrivileges!: boolean;

  created() {
    if (!this.retrieved) this.getArticles();
  }
}
